<template>
  <main class="w-full md:w-11/12 xl:w-11/12 md:mx-auto bg-white">
    <div v-if="!isEdit" class="w-full px-6 py-8">
      <div class="w-full flex items-center justify-between">
        <h4 class="text-lg md:text-2xl text-headerText font-bold">
          Business Owner/CEO/Founder
        </h4>

        <span
          @click="addFounder = true"
          class="md:w-auto flex items-center md:justify-center gap-2 font-medium cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 text-sm py-2"
        >
          <img src="@/assets/icons/newPlus1.svg" alt="" />

          <span
            v-if="allFounders && allFounders.length"
            class="hidden md:flex items-center text-sm text-primary"
          >
            Add More
          </span>

          <span v-else class="hidden md:flex items-center text-sm text-primary">
            Add
          </span>
        </span>
      </div>

      <hr class="border border-newLightgrey my-4" />

      <div v-if="allFounders && allFounders.length" class="w-full">
        <div
          v-for="(founder, i) in allFounders"
          :key="founder._id"
          class="flex flex-col md:flex-row gap-y-4 items-start w-full rounded md:rounded-2xl rounded-t-2xl my-4"
          :style="`border:1px solid ${getColor(i)}`"
        >
          <div
            :style="`background-color:${getColor(i)}`"
            class="w-full flex md:block gap-x-8 md:w-40 self-stretch max-h-full rounded-bl-none rounded-tr-2xl md:rounded-r-none md:rounded-b-2xl md:rounded-br-none rounded-l-2xl p-4"
          >
            <div
              class="rounded rounded-r-none rounded-xl rounded-bl-none md:w-32 md:h-32 w-36 h-36 overflow-hidden"
            >
              <img
                :src="founder.pictureUrl"
                class="object-cover object-center w-full"
                alt=""
              />
            </div>
            <div class="md:mt-9 mb-6 flex flex-col gap-4 items-center">
              <UploadComponent
                :proPic="true"
                @fileUrl="updateProfilePic($event, founder._id)"
              />
              <div
                @click="updateProfilePic(null, founder._id)"
                class="cursor-pointer border border-white rounded-md md:rounded-lg py-3 px-4 md:px-6 flex items-center text-white gap-2 md:gap-4"
              >
                <i class="far fa-times-circle text-xl"></i>
                <span class="font-medium">Remove</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col md:w-full p-4 self-start">
            <div class="flex justify-between items-start w-full relative">
              <h2 class="font-medium capitalize text-2xl text-left text-priBg">
                {{ founder.fullName }}
              </h2>
              <div
                @click="
                  () => {
                    show = !show;
                    active = founder._id;
                  }
                "
                class="absolute -top-48 md:top-1 -right-24 md:-right-3 cursor-pointer z-50 w-4"
              >
                <img
                  class="hidden md:block"
                  src="@/assets/icons/more-icon.svg"
                  alt=""
                />
                <img
                  class="md:hidden"
                  src="@/assets/icons/more-white.svg"
                  alt=""
                />
              </div>
              <div
                v-show="show && active === founder._id"
                class="w-32 group-hover:text-dark-800 flex absolute -right-5 -top-40 md:top-0 mt-6 flex-col z-50 bg-white shadow-lg rounded-xl"
              >
                <div
                  class="flex flex-col z-50 text-left items-start text-dark-800 text-sm cursor-pointer pt-2"
                >
                  <span
                    class="hover:bg-newLightGreen text-black py-2 px-4 w-full rounded-t-xl"
                    @click="editFounder(founder)"
                  >
                    Edit
                  </span>

                  <span
                    @click="
                      () => {
                        founderId = founder._id;
                        deleteWarning = true;
                      }
                    "
                    class="hover:bg-newLightGreen text-black py-2 px-4 w-full rounded-b-xl"
                    >Remove</span
                  >
                </div>
              </div>
            </div>

            <div class="w-full flex flex-col items-start gap-2 mt-4">
              <div
                class="w-full flex flex-col md:flex-col flex-wrap items-start gap-2"
              >
                <div class="flex flex-row items-center gap-x-2">
                  <span class="text-base text-newLightgrey">Email:</span>
                  <span class="text-base text-dark-800 font-medium">{{
                    founder.email
                  }}</span>
                </div>
                <div class="flex flex-row items-center gap-x-2">
                  <span class="text-base text-newLightgrey">Phone Number:</span>
                  <span class="text-base text-dark-800 font-medium">{{
                    founder.phoneNumber
                  }}</span>
                </div>
              </div>
              <div class="w-full flex flex-row items-center gap-x-2">
                <span class="text-base text-newLightgrey">BVN:</span>
                <span class="text-base text-dark-800 font-medium">{{
                  founder.bvn
                }}</span>
              </div>
              <div class="w-full flex flex-row items-center gap-x-2">
                <span class="text-base flex-none text-newLightgrey"
                  >House Address:</span
                >
                <span
                  class="text-sm md:text-base text-dark-800 font-medium break-all"
                  >{{ founder.address }}</span
                >
              </div>
            </div>
            <div class="flex flex-col pb-3 gap-3 mt-4">
              <div
                class="w-full flex flex-col items-center justify-center p-4 bg-newLimeGreen"
              >
                <span
                  class="font-medium text-base text-dark-800 mb-2 text-center"
                  >Means of Identification</span
                >

                <img
                  @click="onPreview(founder.idUrl)"
                  :src="founder.idUrl"
                  class="object-cover object-center w-40"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <easiEmptyState v-else text="No CEO/Founder added" :hideButton="true" />
    </div>

    <div v-else class="w-full px-6 py-10 relative">
      <span
        @click="isEdit = false"
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img src="@/assets/icons/close.svg" alt="" />
      </span>

      <h4 class="text-xl md:text-2xl font-medium">
        Edit Business Owner/CEO/Founder
      </h4>

      <hr class="border-0.5 border-dividerColor my-4" />

      <form
        @submit.prevent="updateExec"
        autocomplete="on"
        class="flex flex-col"
      >
        <label for="title" class="text-dark-800 text-sm font-medium"
          >Title
        </label>
        <easiSelectInput
          required
          class="mt-1 mb-3"
          @update="args.title = $event"
          :value="args.title"
          :options="[
            'Mr',
            'Mrs',
            'Miss',
            'Dr',
            'Prof',
            'Chief',
            'Engr',
            'Pastor',
            'Rev',
            'Hon',
            'Sir',
            'Lady',
            'Alhaji',
            'Alhaja',
            'Madam',
            'Baba',
            'Mama',
          ]"
        />

        <easiTextInput
          placeholder="Full Name"
          type="text"
          name="fullName"
          class="mt-1 mb-5"
          required
          v-model="args.fullName"
          :error="errorRules.fullName"
        ></easiTextInput>

        <easiTextInput
          placeholder="Email"
          type="email"
          name="email"
          class="mt-1 mb-5"
          v-model="args.email"
          required
          autocom
        ></easiTextInput>

        <easiTextInput
          placeholder="Phone Number"
          type="tel"
          name="phoneNumber"
          class="mt-1 mb-5"
          v-model="args.phoneNumber"
          required
          autocom
        ></easiTextInput>

        <easiTextInput
          placeholder="Enter address"
          type="text"
          name="address"
          class="mt-1 mb-5"
          v-model="args.address"
          autocom
          required
        ></easiTextInput>

        <easiTextInput
          placeholder="Enter BVN"
          type="text"
          name="bvn"
          class="mt-1 mb-5"
          v-model="args.bvn"
          autocom
        ></easiTextInput>

        <div class="md:flex items-center gap-4 w-full">
          <div class="flex flex-col w-full md:w-1/2">
            <label
              for="identification"
              class="text-dark-800 text-sm font-medium"
              >Select Means of Identification</label
            >
            <easiSelectInput
              class="mt-1 mb-3"
              @update="args.idType = $event"
              :value="args.idType"
              :options="[
                'National ID',
                'Drivers License',
                'International Passport',
                'Voters Card',
              ]"
            />
          </div>

          <div class="mt-3 md:mt-0 items-center">
            <UploadComponent
              :label="'Upload ID'"
              :pill="true"
              @fileUrl="uploadFile = $event"
            />
          </div>
        </div>

        <hr class="border-0.5 border-dividerColor mt-5 mb-7" />

        <div
          class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <span
            @click="closeFN"
            class="md:w-40 w-full font-medium cursor-pointer text-center bg-white rounded-lg text-dark-800 border border-secondary px-3 text-sm py-3"
            >Cancel</span
          >
          <div class="w-full md:w-auto">
            <easiButtonNew
              type="submit"
              :loading="loading"
              class="w-full md:w-40"
            >
              Update
            </easiButtonNew>
          </div>
        </div>
      </form>
    </div>

    <easiImageView
      :visible="visibleRef"
      :imgs="imgsRef"
      @hide="onHide"
    ></easiImageView>

    <div
      @click="show = false"
      :class="!show ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiModal persistence v-if="addFounder" @close="addFounder = false">
      <template v-slot:header>
        <h4 class="text-xl md:text-2xl font-bold text-headerText">
          Add Business Owner/CEO/Founder
        </h4></template
      >
      <form
        @submit.prevent="createExec"
        autocomplete="on"
        class="flex flex-col gap-y-1"
      >
        <p class="text-sm md:text-lg text-secondary font-bold text-center">
          Please Select From List or Manually Fill This Form
        </p>

        <div class="px-6">
          <easiEmployeeBonus
            @update="mapEmployeeToArgs($event)"
            :value="employeeId"
            :options="createEmpList()"
            :single="true"
            :placeHolder="args.fullName"
            class=""
          />
        </div>
        <hr class="border-0.5 border-dividerColor my-4" />
        <div class="flex px-6 justify-center gap-4 mb-8 w-full">
          <UploadComponent
            :imageLink="args.pictureUrl"
            :profile="true"
            @fileUrl="uploadProfile = $event"
            fullWidth
          />
        </div>

        <div class="px-6">
          <label for="title" class="text-dark-800 text-sm font-medium"
            >Title
          </label>
          <easiSelectInput
            class="mt-1 mb-3"
            @update="args.title = $event"
            :value="args.title"
            :options="[
              'Mr',
              'Mrs',
              'Miss',
              'Dr',
              'Prof',
              'Chief',
              'Engr',
              'Pastor',
              'Rev',
              'Hon',
              'Sir',
              'Lady',
              'Alhaji',
              'Alhaja',
              'Madam',
              'Baba',
              'Mama',
            ]"
          />
        </div>

        <div class="px-6">
          <!-- <label for="fullName" class="text-dark-800 text-sm font-medium mt-6"
            >Full Name</label
          > -->
          <easiTextInput
            placeholder="Full Name"
            type="text"
            name="fullName"
            class="mt-1 mb-5"
            required
            v-model="args.fullName"
            :error="errorRules.fullName"
          ></easiTextInput>
        </div>

        <div class="px-6">
          <easiTextInput
            placeholder="Email"
            type="email"
            name="email"
            class="mt-1 mb-5"
            v-model="args.email"
            required
            autocom
          ></easiTextInput>
        </div>

        <div class="px-6">
          <easiTextInput
            placeholder="phoneNumber"
            type="tel"
            name="phoneNumber"
            class="mt-1 mb-5"
            v-model="args.phoneNumber"
            required
            autocom
          ></easiTextInput>
        </div>

        <div class="px-6">
          <easiTextInput
            placeholder="Enter address"
            type="text"
            name="address"
            class="mt-1 mb-5"
            v-model="args.address"
            required
            autocom
          ></easiTextInput>
        </div>

        <div class="px-6">
          <!-- <label for="bvn" class="text-dark-800 text-sm font-medium">BVN</label> -->
          <easiTextInput
            placeholder="Enter BVN"
            type="number"
            name="bvn"
            class="mt-1 mb-5"
            v-model="args.bvn"
            autocom
          ></easiTextInput>
        </div>

        <div class="px-6">
          <div class="md:flex md:flex-row md:items-center gap-4 w-full">
            <div class="flex flex-col w-full md:w-1/2">
              <label
                for="identification"
                class="text-dark-800 text-sm font-medium"
              >
                MEANS OF ID</label
              >
              <easiSelectInput
                class="mt-1 mb-3"
                @update="args.idType = $event"
                :value="args.idType"
                :options="[
                  'National ID',
                  'Drivers License',
                  'International Passport',
                  'Voters Card',
                ]"
              />
            </div>

            <div class="flex flex-col gap-x-4 gap-y-1">
              <UploadComponent
                :label="'Upload ID'"
                :pill="true"
                @fileUrl="uploadFile = $event"
              />
              <span class="text-newLightgrey text-sm">
                (Jpeg and Png Only) (2mb Max)
              </span>
            </div>
          </div>
        </div>

        <hr class="border-0.5 border-dividerColor mt-5 mb-7" />

        <div
          class="w-full my-3 px-6 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <span
            @click="closeFN"
            class="w-full font-medium cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 text-sm py-3"
            >Cancel</span
          >
          <div class="w-full">
            <easiButton type="submit" :loading="loading" class="w-full">
              Update
            </easiButton>
          </div>
        </div>
      </form>
    </easiModal>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to remove business owner?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButtonNew
              @click="deleteExec(founderId)"
              class="w-40 min-w-full"
              :loading="loading"
              >Yes</easiButtonNew
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span> Business owner(s) updated</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import IndustryList from "@/utils/Industry";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const { getAllEmployees } = storeToRefs(store);
const { processDate, validatePhone, validateEmail, uploadFileToServer } =
  helperFunctions;
const visibleRef = ref(false);
const imgsRef = ref("");
const url = ref("");
const active = ref("");
const founderId = ref("");

const deleteWarning = ref(false);
const addFounder = ref(false);
const show = ref(false);
const loading = ref(false);
const isEdit = ref(false);
const updateSuccess = ref(false);
const companyData = computed(() => store.getCompanyDetails);
const allEmployees = computed(() => getAllEmployees.value.data);
const allFounders = computed(() => store.getFounders.data);
const employeeId = ref([]);
async function queryCompanyExec() {
  loading.value = true;

  try {
    await query({
      endpoint: "ListCompanyExec",
      service: "SETTINGS",
      storeKey: "founders",
      payload: {
        type: "FOUNDER",
      },
    });
    loading.value = false;
  } catch (e) {
    loading.value = false;
  }
}
queryCompanyExec();

function mapEmployeeToArgs(e) {
  if (e && e.length) {
    employeeId.value = e;
    const emp = allEmployees.value.filter((employee) => employee._id === e[0]);
    const empObj = emp[0].profile;

    args.title = empObj.title || "";
    args.fullName = `${empObj.firstName} ${empObj.lastName}`;
    args.email = empObj.email || "";
    args.address = empObj.address || "";
    args.phoneNumber = empObj.phone || "";
    args.pictureUrl = empObj.pfp || "";
  } else {
    args.title = "";
    args.fullName = "";
    args.email = "";
    args.address = "";
    args.phoneNumber = "";
    args.pictureUrl = "";
  }
}

const errorRules = ref({
  phone: "",
  workEmail: "",
  otherPhoneNumbers: "",
  title: "",
});
const imgLink = ref("@/assets/img/cert.png");
const industryData = IndustryList.map((el) => el.name);
const uploadFile = ref(null);
const uploadProfile = ref(null);

const args = reactive({
  title: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  address: "",
  bvn: "",
  idUrl: "",
  idType: "",
  pictureUrl: "",
  execType: "FOUNDER",
});
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});

const getColor = (index) => {
  let color;
  // Get color
  let num;
  const colors = [
    "#D32F2F",
    "#189F00",
    "#0A244F",
    "#E4572E",
    "#01AA8C",
    "#184EA8",
    "#F39C36",
    "#4f46e5",
  ];
  if (index > 9) {
    let firstDigit = String(index).charAt(0);
    num = Number(firstDigit);
  } else {
    num = index;
  }

  // const randomNumber = Math.floor(Math.random() * 5);
  color = colors[num];
  return color;
};

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};

function editFounder(obj) {
  const keys = Object.keys(obj);
  founderId.value = obj._id;

  for (let k of keys) {
    if (args[k] !== undefined) {
      args[k] = obj[k];
    }
  }
  isEdit.value = true;
}

const createExec = async () => {
  const title = args.title;
  if (title === "MR") {
    args.title = "Mr";
  } else if (title === "MRS") {
    args.title = "Mrs";
  } else if (title === "MISS") {
    args.title = "Miss";
  }
  if (args.bvn.length !== 11) {
    toast.error("BVN must have 11 characters");
    throw new Error(" BVN validation failed");
  }
  loading.value = true;
  // args.idUrl = await uploadFileToServer(uploadFile.value);
  // if (uploadProfile.value && uploadProfile.value.length) {
  //   args.pictureUrl = await uploadFileToServer(uploadProfile.value);
  // }

  if (uploadFile.value) {
    const url = await uploadFileToServer(uploadFile.value);
    if (url) {
      args.idUrl = url;
    } else {
      toast.error("ID upload failed");
      return false;
    }
  }
  if (uploadProfile.value) {
    const picture = await uploadFileToServer(uploadProfile.value);
    if (picture) {
      args.pictureUrl = picture;
    } else {
      toast.error("Passport upload failed");
      return false;
    }
  }

  try {
    let res = await mutate({
      endpoint: "CreateCompanyExec",
      data: { input: args },
      service: "SETTINGS",
    });
    console.log(res);

    if (res.success) {
      loading.value = false;

      await queryCompanyExec();
      closeFN();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
};

async function updateProfilePic(e, id) {
  loading.value = true;
  const pictureUrl = await uploadFileToServer(e);

  try {
    let res = await mutate({
      endpoint: "UpdateCompanyExec",
      data: { input: { pictureUrl }, execId: id },
      service: "SETTINGS",
    });
    if (res.success) {
      console.log(res);
      loading.value = false;
      await queryCompanyExec();
      updateSuccess.value = true;
      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
    } else {
      loading.value = false;
      toast.error("Image is required");
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}

const updateExec = async () => {
  if (args.bvn.length !== 11) {
    toast.error("BVN must have 11 characters");
    throw new Error(" BVN validation failed");
  }
  loading.value = true;
  if (uploadFile.value) {
    args.idUrl = await uploadFileToServer(uploadFile.value);
  }
  // args.pictureUrl = await uploadFileToServer(uploadProfile.value);
  try {
    let res = await mutate({
      endpoint: "UpdateCompanyExec",
      data: { input: args, execId: founderId.value },
      service: "SETTINGS",
    });

    if (res.success) {
      loading.value = false;

      await queryCompanyExec();
      closeFN();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
};
async function deleteExec(id) {
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "DeleteCompanyExec",
      data: { execId: id },
      service: "SETTINGS",
    });

    if (res.success) {
      loading.value = false;

      await queryCompanyExec();
      closeFN();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
      loading.value = false;
      deleteWarning.value = false;
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}
function useFile(e) {
  console.log(e, "E");
  // uploadFile.value = e.target.files[0];
  url.value = e;
}
const onPreview = (img) => {
  console.log(img);
  imgsRef.value = img;
  visibleRef.value = true;
};

// let url = ref("");
function previewFiles(e) {
  const file = e.target.files[0];
  let reader = new FileReader();

  reader.onloadend = (e) => {
    // data url
    url.value = e.target.result;
    console.log(e.target.result, "K");
  };
  reader.readAsDataURL(file);
  // url = URL.createObjectURL(file);
}

const onHide = () => (visibleRef.value = false);

const closeFN = () => {
  addFounder.value = false;
  isEdit.value = false;
  deleteWarning.value = false;

  store.$patch({
    pageIndex: 0,
  });
  // window.location.reload();
};
</script>

<style>
.color {
  color: #b9bbc07d;
  color: #e0e0e08b;
}
</style>
