<template>
  <main class="w-full md:w-11/12 xl:w-11/12 md:mx-auto bg-white">
    <div v-if="!isEdit" class="w-full px-6 py-8">
      <div class="w-full flex items-center justify-between">
        <h4 class="text-xl md:text-3xl font-medium text-headerText">
          Busines Profile
        </h4>
        <easiButton
          @click="isEdit = true"
          size="small"
          variant="outlined"
          color="primary"
          class="py-1"
        >
          <div class="flex items-center gap-x-3 px-1">
            <img class="" src="@/assets/icons/Edit3.svg" alt="" />

            <span class="text-sm"> Edit </span>
          </div>
        </easiButton>
      </div>

      <hr class="border border-newLightgrey my-8" />

      <div
        class="easiGradient relative overflow-hidden w-full p-8 flex flex-col md:flex-row justify-center items-center gap-4"
      >
        <div
          class="rounded-full w-44 h-44 border-4 border-white overflow-hidden"
        >
          <img
            v-if="companyData.company.logo"
            :src="companyData.company.logo"
            class="object-cover object-center w-full h-full"
            alt=""
          />

          <img
            v-else
            src="@/assets/img/c-logo.png"
            class="object-cover object-center w-full h-full"
            alt=""
          />
        </div>
        <div class="flex flex-row md:flex-col gap-4">
          <UploadComponent :proPic="true" @fileUrl="updateProfilePic($event)" />

          <div
            @click="updateProfilePic(null)"
            class="cursor-pointer border border-white rounded-lg py-3 px-4 md:px-6 flex items-center text-gray-400 gap-2 md:gap-4"
          >
            <i class="far fa-times-circle text-xl text-white"></i>
            <span class="font-medium text-white">Cancel</span>
          </div>
        </div>

        <div
          class="absolute -right-24 bottom-4 w-56 opacity-25 pointer-events-none"
        >
          <img src="@/assets/icons/auth-illustration.svg" alt="" />
        </div>
      </div>

      <hr class="my-8 border border-newLightgrey" />

      <h3 class="font-bold text-primary-deep capitalize text-2xl md:text-2xl">
        {{ companyData.company.companyName }}
      </h3>
      <hr class="my-8 border border-newLightgrey" />

      <div class="flex flex-col gap-y-4 w-full">
        <div class="flex flex-col gap-3">
          <span class="font-medium text-dark-800 text-lg">CAC Document</span>
          <div class="w-full p-4 bg-newLimeGreen">
            <img
              @click="onPreview(companyData.company.cacDocument)"
              :src="companyData.company.cacDocument"
              class="object-cover object-center w-40"
              alt=""
            />
          </div>
        </div>
      </div>
      <hr class="my-6 border-0.5 border-dividerColor" />

      <div class="w-full grid grid-cols-2 items-start gap-4 mt-4">
        <div
          class="w-full flex flex-col md:flex-row flex-wrap items-start gap-4"
        >
          <div class="flex flex-col items-start">
            <span class="text-base text-newLightgrey font-normal"
              >Company Size</span
            >
            <span class="text-lg text-dark-800 font-medium">{{
              (companyData.company && companyData.company.companySize) || "N/A"
            }}</span>
          </div>
          <!-- <div class="flex flex-col items-start">
            <span class="text-xs text-dark-600">Last Name</span>
            <span class="text-sm text-dark-800 font-medium">Obasanha</span>
          </div> -->
        </div>
        <div class="w-full flex flex-col items-start">
          <span class="text-base text-newLightgrey font-normal"
            >Pension Code</span
          >
          <span class="text-lg text-dark-800 font-medium">{{
            (companyData.company && companyData.company.employerPensionCode) ||
            "N/A"
          }}</span>
        </div>
        <div class="w-full flex flex-col items-start">
          <span class="text-base text-newLightgrey font-normal"
            >PAYE State</span
          >
          <span class="text-lg text-dark-800 font-medium">{{
            (companyData.company && companyData.company.payeState) || "N/A"
          }}</span>
        </div>

        <div class="w-full flex flex-col items-start">
          <span class="text-base text-newLightgrey font-normal">PAYE ID</span>
          <span class="text-lg text-dark-800 font-medium">{{
            (companyData.company && companyData.company.payeID) || "N/A"
          }}</span>
        </div>

        <div class="w-full flex flex-col items-start">
          <span class="text-base text-newLightgrey font-normal">NHF Code</span>
          <span class="text-lg text-dark-800 font-medium">{{
            (companyData.company && companyData.company.nhfCode) || "N/A"
          }}</span>
        </div>

        <div class="w-full flex flex-col items-start">
          <span class="text-base text-newLightgrey font-normal">Website</span>
          <span class="text-lg text-secondary font-medium break-all">{{
            (companyData.company && companyData.company.website) || "N/A"
          }}</span>
        </div>

        <div class="w-full flex flex-col items-start">
          <span class="text-base text-newLightgrey font-normal">Industry</span>
          <span class="text-lg text-dark-800 font-medium">{{
            (companyData.company && companyData.company.industry) || "N/A"
          }}</span>
        </div>
      </div>
    </div>

    <div v-else class="w-full px-6 py-10 relative">
      <span
        @click="isEdit = false"
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img src="@/assets/icons/close.svg" alt="" />
      </span>

      <h4 class="text-2xl md:text-3xl text-center text-headerText font-medium">
        Edit Business Profile
      </h4>

      <hr class="border border-newLightgrey my-8" />

      <form
        @submit.prevent="updateBusinessProfile"
        autocomplete="on"
        class="flex flex-col"
      >
        <easiTextInput
          placeholder="Business Name"
          type="text"
          name="businessName"
          class="mt-1 mb-5"
          required
          v-model="args.companyName"
          :error="errorRules.businessName"
        ></easiTextInput>
        <hr class="border border-newLightgrey my-8" />

        <div class="flex gap-x-4 items-center mb-4">
          <span class="text-dark-800 text-lg font-medium"
            >Registered Company?
          </span>

          <div class="flex items-center gap-x-2">
            <div class="flex items-center">
              <label for="" class="text-dark-800 text-sm font-medium"
                >Yes</label
              >
              <input
                @change="args.registered = true"
                :value="true"
                :checked="args.registered === true"
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
              />
            </div>

            <div class="flex items-center">
              <label for="" class="text-dark-800 text-sm font-medium">No</label>
              <input
                @change="args.registered = false"
                :value="false"
                :checked="args.registered === false"
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
              />
            </div>
          </div>
        </div>
        <div
          v-show="args.registered"
          class="-mt-2 mb-6 flex gap-x-4 items-center"
        >
          <UploadComponent
            :label="'Choose File'"
            :pill="true"
            @fileUrl="uploadFile = $event"
            :info="'Upload CAC Document (Jpeg and Png Only) (10mb max) '"
            :clear="removeFiles"
          />
        </div>
        <hr class="border border-newLightgrey my-8" />

        <easiTextInput
          placeholder="Office Address"
          type="text"
          name="officeAddress"
          class="mt-1 mb-5"
          required
          v-model="args.address"
          :error="errorRules.officeAddress"
        ></easiTextInput>
        <div class="mt-2 mb-6 flex gap-x-4 items-center">
          <UploadComponent
            :label="'Choose File'"
            :pill="true"
            @fileUrl="uploadProofOfAddress = $event"
            :info="'Proof of Office Address '"
            :clear="removeFiles"
          />
        </div>
        <hr class="border border-newLightgrey mt-2 mb-7" />

        <div class="md:grid flex flex-col grid-cols-2 gap-4 items-center">
          <div>
            <label class="text-dark-800 text-sm font-medium"
              >Company Size</label
            >
            <easiSelectInput
              class="mt-1 mb-3"
              @update="args.companySize = $event"
              :value="args.companySize"
              :options="['1-20', '21-50', '51-100', '100 Above']"
            />
          </div>

          <div class="mt-3">
            <easiTextInput
              placeholder="Pension Code"
              type="text"
              name="pensionCode"
              class="mt-1 mb-5"
              v-model="args.employerPensionCode"
              autocom
            ></easiTextInput>
          </div>

          <div>
            <label class="text-dark-800 text-sm font-medium"
              >State For PAYE</label
            >
            <easiSelectInput2
              required
              class="mt-1 mb-3"
              @update="args.payeState = $event"
              :value="args.payeState"
              :options="statesData"
            />
          </div>

          <div class="mt-3">
            <easiTextInput
              placeholder="PAYE ID"
              type="text"
              name="payeId"
              class="mt-1 mb-5"
              v-model="args.payeID"
              autocom
            ></easiTextInput>
          </div>

          <div>
            <label for="nhfCode" class="text-dark-800 text-sm font-medium"
              >NHF Code</label
            >
            <easiTextInput
              placeholder="NHF Code"
              type="text"
              name="nhfCode"
              class="mt-1 mb-5"
              v-model="args.nhfCode"
              autocom
            ></easiTextInput>
          </div>

          <div class="mt-5">
            <!-- <label for="website" class="text-dark-800 text-sm font-medium"
              >Website</label
            > -->
            <easiTextInput
              placeholder="Website"
              type="text"
              name="website"
              class="mt-1 mb-5"
              v-model="args.website"
              autocom
            ></easiTextInput>
          </div>
          <hr
            class="border hidden md:block border-newLightgrey my-4 col-span-2"
          />
          <div class="col-span-2">
            <label class="text-dark-800 text-sm font-medium">Industry</label>
            <easiSelectInput
              class="mt-1 mb-3"
              @update="args.industry = $event"
              :value="args.industry"
              :options="industryData"
            />
          </div>
          <hr
            class="border md:hidden border-dividerColor mt-2 mb-7 col-span-2"
          />
        </div>

        <div
          class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <span
            @click="closeFN"
            class="w-full font-medium cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 text-sm py-3"
            >Cancel</span
          >
          <div class="w-full">
            <easiButtonNew type="submit" :loading="loading" class="w-full">
              Update
            </easiButtonNew>
          </div>
        </div>
      </form>
    </div>

    <easiImageView
      :visible="visibleRef"
      :imgs="imgsRef"
      @hide="onHide"
    ></easiImageView>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span> Business profile updated</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import IndustryList from "@/utils/Industry";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const { processDate, validatePhone, validateEmail, uploadFileToServer } =
  helperFunctions;

const removeFiles = ref(false);
const visibleRef = ref(false);
const updateSuccess = ref(false);
const imgsRef = ref("");
const uploadFile = ref(null);
const uploadProofOfAddress = ref(null);
const loading = ref(false);
const isEdit = ref(false);
const companyData = computed(() => store.getCompanyDetails);
const errorRules = ref({
  phone: "",
  workEmail: "",
  otherPhoneNumbers: "",
  businessName: "",
});
const imgLink = ref("@/assets/img/cert.png");
const industryData = IndustryList.map((el) => el.name);

const args = reactive({
  companyName: "",
  website: "",
  industry: "",
  companySize: "",
  employerPensionCode: "",
  tin: "",
  address: "",
  country: "",
  state: "",
  proofOfAddress: "",
  logo: "",
  registered: false,
  nhfCode: "",
  payeID: "",
  payeState: "",
  cacDocument: "",
});

function assignValues() {
  const keys = [
    "companyName",
    "address",
    "companySize",
    "employerPensionCode",
    "industry",
    "website",
  ];
  for (let i = 0; i < keys.length; i++) {
    if (companyData.value.company[keys[i]]) {
      args[keys[i]] = companyData.value.company[keys[i]];
    }
  }
}

const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});
const updateProfile = () => {
  console.log(args);
};

const onPreview = (img) => {
  console.log(img);
  imgsRef.value = img;
  visibleRef.value = true;
};

const onHide = () => (visibleRef.value = false);

const reloadPage = () => {
  store.$patch({
    pageIndex: 0,
  });
  isEdit.value = false;
  // window.location.reload();
};

async function queryCompany() {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
}
queryCompany();
assignValues();

function closeFN() {
  store.$patch({
    pageIndex: 0,
  });
  isEdit.value = false;
}

async function updateBusinessProfile() {
  loading.value = true;

  let payload = {};

  if (uploadFile.value) {
    args.cacDocument = await uploadFileToServer(uploadFile.value);
  }
  if (uploadProofOfAddress.value) {
    args.proofOfAddress = await uploadFileToServer(uploadProofOfAddress.value);
  }
  const keys = Object.keys(args);

  for (let k of keys) {
    if (args[k].length) {
      payload[k] = args[k];
    }
  }
  if (payload["website"] && !payload["website"].includes("http")) {
    payload["website"] = `http://${payload.website}`;
  }

  try {
    let res = await mutate({
      endpoint: "UpdateBusinessProfile",
      data: { input: payload },
      service: "SETTINGS",
    });
    if (res.success) {
      console.log(res);
      removeFiles.value = true;
      uploadFile.value = null;
      uploadProofOfAddress.value = null;
      loading.value = false;
      await queryCompany();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    loading.value = false;
  }
}

async function updateProfilePic(e) {
  loading.value = true;

  const logo = await uploadFileToServer(e);
  try {
    let res = await mutate({
      endpoint: "UpdateBusinessProfile",
      data: { input: { logo } },
      service: "SETTINGS",
    });
    if (res.success) {
      loading.value = false;
      // console.log(res);

      await queryCompany();
      updateSuccess.value = true;
      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
    } else {
      toast.error("Logo is required");
      loading.value = false;
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}
</script>

<style>
.color {
  color: #b9bbc07d;
  color: #e0e0e08b;
}
</style>
