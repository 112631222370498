<template>
  <main
    class="w-full md:w-full xl:w-full md:border-none border-t border-dividerColor md:my-0 my-6 bg-white"
  >
    <div v-if="!isEdit" class="w-full">
      <div class="flex items-center md:border md:border-b-dark-900 p-3 md:p-0">
        <div
          @click="branch = true"
          :class="branch ? 'bg-newLightGreen' : ''"
          class="w-full rounded rounded-tl-2xl md:rounded-none md:py-5 p-2 flex items-center justify-center gap-x-4 cursor-pointer"
        >
          <img
            v-show="!branch"
            src="@/assets/icons/newBranchActive.svg"
            alt=""
          />
          <img
            v-show="branch"
            src="@/assets/icons/newBranchActive.svg"
            alt=""
          />
          <h3
            :class="branch ? 'text-dark-900' : 'text-grey '"
            class="font-bold text-lg md:text-base"
          >
            Branch
          </h3>
        </div>

        <div
          @click="branch = false"
          :class="!branch ? 'bg-newLightGreen' : ''"
          class="w-full rounded rounded-tr-2xl md:rounded-none md:p-4 md:py-5 p-4 flex justify-center items-center gap-x-4 cursor-pointer"
        >
          <img
            v-show="branch"
            src="@/assets/icons/newDepartment.svg"
            alt=""
          />
          <img
            v-show="!branch"
            src="@/assets/icons/newDepartmentActive.svg"
            alt=""
          />
          <h3
            :class="!branch ? 'text-dark-900' : 'text-grey '"
            class="font-bold text-lg md:text-base"
          >
            Department
          </h3>
        </div>
      </div>
      <div
        class="w-full flex items-center justify-center md:justify-end mx-auto px-6 py-3"
      >
        <span
          class="md:w-auto md:self-end flex items-center md:justify-end justify-center gap-3 font-medium cursor-pointer text-center bg-white border border-primary rounded-full text-dark-800 px-5 text-sm py-2"
        >
          <img class="w-3" src="@/assets/icons/newPlus1.svg" alt="" />

          <span
            @click="branch ? (addBranch = true) : (addDept = true)"
            class="flex items-center text-sm text-primary"
          >
            Add {{ branch ? "Branch" : "Department" }}
          </span></span
        >
      </div>

      <div v-if="branch">
        <div v-if="allBranches && allBranches.length">
          <div
            v-for="branch in allBranches"
            :key="branch._d"
            class="border-b-2 last:border-b-0 border-newLightgrey mb-4 relative w-full md:w-11/12 mx-auto p-3 md:p-6 flex flex-row md:flex-row justify-between items-center gap-4"
          >
            <div class="flex flex-col">
              <h4
                class="md:text-xl text-base font-bold text-dark-800 capitalize"
              >
                {{ branch.name }}
              </h4>
              <div class="flex items-center gap-x-2 md:gap-x-4">
                <span class="text-base font-medium text-newLightgrey">{{
                  branch.address
                }}</span>
                <span class="md:block text-base font-medium text-newLightgrey"
                  >Employee (s):
                  {{ branch.employees ? branch.employees.length : 0 }}</span
                >
              </div>
            </div>

            <div class="md:flex flex-col">
              <div class="flex gap-x-2 items-center">
                <div
                  :style="`background-color:${
                    getAvatar(branch.branchHead).color
                  }`"
                  :class="
                    branch.branchHead && branch.branchHead.profile.pfp
                      ? 'border border-secondary'
                      : ''
                  "
                  class="md:p-2 pt-1 pl-1 pr-1 md:h-9 md:w-9 w-7 h-7 text-xs md:text-sm text-white text-center font-medium rounded-full"
                >
                  {{ getAvatar(branch.branchHead).name }}
                </div>
                <div class="flex flex-col">
                  <h4
                    class="md:text-sm text-xs font-medium text-dark-800 capitalize"
                  >
                    {{
                      branch.branchHead && branch.branchHead.profile.firstName
                    }}
                    {{
                      branch.branchHead && branch.branchHead.profile.lastName
                    }}
                  </h4>
                  <span class="text-xs font-medium text-dark-600"
                    >Branch Head</span
                  >
                </div>
              </div>
            </div>

            <div
              @click="toggleShow(branch._id)"
              class="cursor-pointer w-4 relative"
            >
              <img class="z-40" src="@/assets/icons/more-icon.svg" alt="" />
              <div
                v-if="show && showId === branch._id"
                class="absolute top-5 right-2 w-40 group-hover:text-dark-800 flex flex-col z-50 bg-white shadow-lg  rounded-xl"
              >
                <div
                  class="flex flex-col z-50 text-left items-start text-dark-800 text-sm cursor-pointer pt-2"
                >
                  <span
                    class="hover:bg-newLightGreen text-black py-2 px-4 w-full  rounded-t-xl"
                    @click="toggleEdit(branch._id)"
                  >
                    Edit
                  </span>

                  <span
                    @click="(deleteWarning = true), (deleteId = branch._id)"
                    class="hover:bg-newLightGreen text-black rounded-b-xl py-2 px-4 w-full"
                    >Remove</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center p-10">
          <span>No Branch Found</span>
        </div>
      </div>

      <div v-else>
        <div v-if="allDepartments && allDepartments.length">
          <div
            v-for="department in allDepartments"
            :key="department._id"
            class="border-b-2 last:border-b-0 border-newLightgrey mb-4 relative w-full md:w-11/12 mx-auto p-3 md:p-6 flex flex-row md:flex-row justify-between items-center gap-4"
          >
            <div class="flex flex-col">
              <h4 class="md:text-xl font-bold text-dark-800 capitalize">
                {{ department.name }}
              </h4>
              <div class="flex items-center gap-x-2 md:gap-x-4">
                <span class="md:block text-base font-medium text-newLightgrey"
                  >Employee (s):
                  {{
                    department.employees ? department.employees.length : 0
                  }}</span
                >
              </div>
            </div>

            <div class="md:flex flex-col">
              <div class="flex gap-x-2 items-center">
                <div
                  :style="`background-color:${
                    getAvatar(department.departmentHead).color
                  }`"
                  :class="
                    department.departmentHead &&
                    department.departmentHead.profile.pfp
                      ? 'border border-secondary'
                      : ''
                  "
                  class="md:p-2 pt-1 pl-1 pr-1 md:h-9 md:w-9 w-7 h-7 text-xs text-center md:text-sm text-white font-medium rounded-full"
                >
                  {{ getAvatar(department.departmentHead).name }}
                </div>
                <div class="flex flex-col">
                  <h4
                    class="md:text-sm text-xs font-medium text-dark-800 capitalize"
                  >
                    {{
                      department.departmentHead &&
                      department.departmentHead.profile.firstName
                    }}
                    {{
                      department.departmentHead &&
                      department.departmentHead.profile.lastName
                    }}
                  </h4>
                  <span class="text-xs font-medium text-dark-600"
                    >Department Head</span
                  >
                </div>
              </div>
            </div>

            <div
              @click="toggleShow(department._id)"
              class="cursor-pointer w-4 relative"
            >
              <img class="z-40" src="@/assets/icons/more-icon.svg" alt="" />
              <div
                v-if="show && showId === department._id"
                class="absolute top-5 right-2 w-40 group-hover:text-dark-800 flex flex-col z-50 bg-white shadow-lg rounded-xl"
              >
                <div
                  class="flex flex-col z-50 text-left items-start text-dark-800 text-sm cursor-pointer pt-2 "
                >
                  <span
                    class="hover:bg-newLightGreen text-black py-2 px-4 w-full rounded-t-xl"
                    @click="toggleEdit(department._id)"
                  >
                    Edit
                  </span>

                  <span
                    @click="(deleteWarning = true), (deleteId = department._id)"
                    class="hover:bg-newLightGreen text-black py-2 px-4 w-full rounded-b-xl"
                    >Remove</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center p-10">
          <span>No Department Found</span>
        </div>
      </div>
    </div>

    <div v-if="isEdit && branch" class="w-full px-6 py-10 relative">
      <EditBranch @close="isEdit = false" />
    </div>

    <div v-if="isEdit && !branch" class="w-full px-6 py-10 relative">
      <EditDept @close="isEdit = false" />
    </div>

    <div
      @click="show = false"
      :class="!show ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiModal v-if="addBranch" @close="addBranch = false" :isHeader="false">
      <EditBranch @close="addBranch = false" :type="true" />
    </easiModal>

    <easiModal v-if="addDept" @close="addDept = false" :isHeader="false">
      <EditDept @close="addDept = false" :type="true" />
    </easiModal>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span
          >Are you sure you want to remove
          {{ branch ? "Branch" : "Department" }}?</span
        >
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              :class="loading.delete ? 'pointer-events-none' : ''"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              @click="deleteFN"
              class="w-40 min-w-full"
              :loading="loading.delete"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ branch ? "Branch" : "Department" }} deleted successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading.branch || loading.department" />
  </main>
</template>

<script setup>
import { ref, reactive, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import UploadComponent from "@/components/global/UploadComponent";
import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
import NigerianStates from "@/utils/states";
import IndustryList from "@/utils/Industry";
import EditBranch from "@/components/Settings/Edit/Branch.vue";
import EditDept from "@/components/Settings/Edit/Dept.vue";
const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const { getAllBranches, getAllDepartments, getAllEmployees } =
  storeToRefs(store);

const { processDate, validatePhone, validateEmail, log, getAvatar } =
  helperFunctions;
const visibleRef = ref(false);
const imgsRef = ref("");
const allEmployees = computed(() => getAllEmployees.value.data);
const loading = ref({
  branch: false,
  department: false,
  delete: false,
});

const queryBranch = async () => {
  loading.value.branch = true;
  try {
    await query({
      endpoint: "ListBranches",
      service: "SETTINGS",
      storeKey: "listBranches",
    });
    loading.value.branch = false;
    console.log("Branches");
  } catch (err) {
    loading.value.branch = false;
    console.log(err);
  }
};

const queryDepartment = async () => {
  loading.value.department = true;
  try {
    await query({
      endpoint: "ListDepartments",
      service: "SETTINGS",
      storeKey: "listDepartments",
    });

    loading.value.department = false;
    console.log("Departments");
  } catch (err) {
    loading.value.department = false;
    console.log(err);
  }
};

queryBranch();
queryDepartment();

const allBranches = computed(() => getAllBranches.value.data);
const allDepartments = computed(() => getAllDepartments.value.data);

// log(allBranches.value);
// log(allDepartments.value);

const branch = ref(true);

const updateSuccess = ref(false);
const deleteWarning = ref(false);
const deleteId = ref("");
const addBranch = ref(false);
const addDept = ref(false);
const show = ref(false);
const showId = ref("");

const isEdit = ref(false);
// const companyData = computed(() => store.getCompanyDetails);
const errorRules = ref({
  phone: "",
  workEmail: "",
  otherPhoneNumbers: "",
  businessName: "",
});
const imgLink = ref("@/assets/img/cert.png");
const industryData = IndustryList.map((el) => el.name);

const args = reactive({
  businessName: "",
  officeAddress: "",
  email: "",
  phone: "",
  stateForPaye: "",
  companySize: "",
  payeId: "",
  nhfCode: "",
  websiteUrl: "",
  industry: "",
});

const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};

const toggleShow = (id) => {
  if (show.value) {
    show.value = false;
    showId.value = "";
  } else {
    show.value = true;
    showId.value = id;
  }
};

const toggleEdit = (id) => {
  isEdit.value = true;
  if (branch.value) {
    window.localStorage.setItem("branchId", id);
  } else {
    window.localStorage.setItem("departmentId", id);
  }
};

const deleteFN = async () => {
  console.log(deleteId.value);
  loading.value.delete = true;

  let endpoint, idName;
  if (branch.value) {
    endpoint = "DeleteBranch";
    idName = "branchId";
  } else {
    endpoint = "DeleteDepartment";
    idName = "departmentId";
  }

  try {
    mutate({
      endpoint,
      service: "SETTINGS",
      data: { [idName]: deleteId.value },
    })
      .then((res) => {
        if (res.success) {
          loading.value.delete = false;
          updateSuccess.value = true;
          deleteWarning.value = false;

          setTimeout(() => {
            updateSuccess.value = false;
            branch.value ? queryBranch() : queryDepartment();
            // window.location.reload();
          }, 500);
          // toast.success("Bank Record Updated Successfully");
        } else {
          loading.value.delete = false;
          toast.error(res.message);
        }
      })
      .catch((e) => {
        console.log(e);
        loading.value.delete = false;
      });
  } catch (e) {
    loading.value.delete = false;
    console.log(e);
  }
};

const updateProfile = () => {
  console.log(args);
};

const onPreview = (img) => {
  console.log(img);
  imgsRef.value = img;
  visibleRef.value = true;
};

const onHide = () => (visibleRef.value = false);

const reloadPage = () => {
  store.$patch({
    pageIndex: 0,
  });
  window.location.reload();
};
</script>

<style>
.color {
  color: #b9bbc07d;
  color: #e0e0e08b;
}
</style>
